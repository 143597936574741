import React from "react";
import { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Api from "app/services/api";
import Path from "app/route/Path";
import DefaultLayout from "app/layouts/DefaultLayout";
import Salon from "app/components/Salon";
import SearchBox from "app/components/common/SearchBox";
import { salons } from "app/services/api";
import BackBtn from "../../../components/common/BackButton";

function SalonSearchPage(props) {
  const navigate = useNavigate();
  const queryString = useLocation().search;

  const queries = useMemo(
    () => new URLSearchParams(queryString),
    [queryString]
  );
  const nextSearchText = useMemo(() => {
    return queries.get("q");
  }, [queries]);

  const [searchedText, setSearchedText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchSalons, setSearchSalons] = useState([]);
  // const [searchSalonsPending, setSearchSalonsPending] = useState(false);
  const [searchSalonsLoaded, setSearchSalonsLoaded] = useState(false);

  useEffect(() => {
    // const firstSearchText = queries.get("q");
    // setSearchedText(queries.get("q") ? queries.get("q") : "");
    const handleGetSalons = async (searchText) => {
      await salons.getSalonApi(searchText).then((data) => {
        const salons = data.data;
        setSearchSalons(salons);
        setSearchSalonsLoaded(true);
      });
    };
    handleGetSalons(nextSearchText);
    setSearchText(nextSearchText);
  }, [nextSearchText]);

  // useEffect(() => {
  //   async function searchSalon(searchedText) {
  //     const response = await Api.salons.search(searchedText);
  //     const items = response.data;
  //     setSearchSalons(items);
  //     setSearchSalonsLoaded(true);
  //   }
  //   searchSalon(searchedText);

  //   const handleGetSalons = async (searchText) => {
  //     await salons
  //       .getSalonApi(searchText)
  //       .then((data) => {
  //         console.log("get salons success data: ", data);
  //       })
  //       .catch((error) => {
  //         console.log("get salon error: ", error);
  //       });
  //   };
  //   handleGetSalons();
  // }, []);

  const salonItemClicked = (salonItem) => {
    navigate(Path.salonDetail(salonItem.id), {
      state: { salonItem: salonItem },
    });
  };

  const searchClicked = async (evt) => {
    // if (searchText === "") {
    //   return;
    // }
    // setSearchSalonsLoaded(false);
    // const response = await Api.salons.search(searchText);
    // const items = response.data;
    // setSearchSalons(items);
    // setSearchSalonsLoaded(true);
    // console.log("searchClicked");
    // setSearchedText(searchText);

    // console.log("searchText: ", searchText);
    // setSearchSalonsLoaded(false);
    // await salons.getSalonApi(searchText).then((data) => {
    //   const salons = data.data.result;
    //   setSearchSalons(salons);
    //   setSearchSalonsLoaded(true);
    // });
    console.log("RICKT -- searchClicked --");
    navigate(`${Path.salonSearch}?q=${searchText}`);
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "検索結果",
      }}
    >
      <SearchBox
        placeholder="美容室を探す"
        value={searchText}
        setValue={setSearchText}
        onSearch={searchClicked}
      />

      <h1>「{nextSearchText}」の検索結果</h1>

      <Salon.Grid
        items={searchSalons}
        loading={!searchSalonsLoaded}
        pending={false}
      >
        <Salon.GridPending>検索ボタンを押してください。</Salon.GridPending>
        <Salon.GridLoading>検索中</Salon.GridLoading>
        <Salon.GridEmpty>該当美容室がないです。</Salon.GridEmpty>
        <Salon.GridItems>
          {searchSalons.map((item) => (
            <Salon.GridItem key={item.id} onClicked={salonItemClicked}>
              {item}
            </Salon.GridItem>
          ))}
        </Salon.GridItems>
      </Salon.Grid>
      <BackBtn />
    </DefaultLayout>
  );
}

export default SalonSearchPage;
