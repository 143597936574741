import React from "react";
import { useLocation } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Reservation from "app/components/Reservation";
import BackBtn from "app/components/common/BackButton";

function ReservationDetailPage(props) {
  const { state } = useLocation();
  const { reservationItem } = state;

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: reservationItem.name,
      }}
    >
      <Reservation.Detail>{reservationItem}</Reservation.Detail>
      <BackBtn/>
    </DefaultLayout>
  );
}

export default ReservationDetailPage;
