import VisitGrid from "./VisitGrid";
import VisitGridLoading from "./VisitGridLoading";
import VisitGridEmpty from "./VisitGridEmpty";
import VisitGridItems from "./VisitGridItems";
import VisitGridItem from "./VisitGridItem";
import VisitDetail from "./VisitDetail";

const exportObjs = {
  Grid: VisitGrid,
  GridLoading: VisitGridLoading,
  GridEmpty: VisitGridEmpty,
  GridItems: VisitGridItems,
  GridItem: VisitGridItem,
  Detail: VisitDetail,
};

export default exportObjs;
