import React from "react";
import { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import SegmentedControl from "app/components/common/SegmentedControl";
import Product from "app/components/Product";
import BackBtn from "../../components/common/BackButton";

function RecommendedProductPage() {
  const { segmentName } = useParams();
  const navigate = useNavigate();

  const [segmentIndex, setSegmentIndex] = useState(
    segmentName === "normal" ? 1 : 0
  );

  const [exclusiveItems, setExclusiveItems] = useState([]);
  const [exclusiveItemLoaded, setExclusiveItemLoaded] = useState(false);
  const [normalItems, setNormalItems] = useState([]);
  const [normalItemLoaded, setNormalItemLoaded] = useState(false);

  const segments = [
    {
      label: "サロン専売品",
      value: "exclusive",
      ref: useRef(),
    },
    {
      label: "市販品",
      value: "normal",
      ref: useRef(),
    },
  ];

  const findFromSegmentsOrDefault = (segments, value, defaultIndex = 0) => {
    const index = segments.findIndex((segment) => segment.value === value);
    return index === -1 ? defaultIndex : index;
  };
  const defaultIndex = findFromSegmentsOrDefault(segments, segmentName, 0);

  useEffect(() => {
    // Reload the form page
    setSegmentIndex(defaultIndex);
  }, [segmentIndex, segmentName, defaultIndex]);

  const segmentChanged = (value, index) => {
    setSegmentIndex(index);
    navigate(Path.recommended(value));
  };

  useEffect(() => {
    async function getAllRecommendedExclusiveProducts() {
      const response = await Api.recommendedProducts.exclusive.all();
      const items = response.data;
      setExclusiveItems(items);
      setExclusiveItemLoaded(true);
    }
    async function getAllRecommendedNormalProducts() {
      const response = await Api.recommendedProducts.normal.all();
      const items = response.data;
      setNormalItems(items);
      setNormalItemLoaded(true);
    }

    getAllRecommendedExclusiveProducts();
    getAllRecommendedNormalProducts();
  }, []);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "おすすめ商品",
      }}
    >
      <SegmentedControl
        name="group-1"
        callback={(val, idx) => segmentChanged(val, idx)}
        controlRef={useRef()}
        segments={segments}
        defaultIndex={segmentIndex}
      />
      {segmentIndex === 0 ? (
        <Product.Grid items={exclusiveItems} loading={!exclusiveItemLoaded}>
          <Product.GridLoading>ローディング中</Product.GridLoading>
          <Product.GridEmpty>
            該当サロン専用おすすめ商品がないです。
          </Product.GridEmpty>
          <Product.GridItems>
            {exclusiveItems.map((item) => (
              <Product.GridItem key={item.id}>{item}</Product.GridItem>
            ))}
          </Product.GridItems>
        </Product.Grid>
      ) : (
        <Product.Grid items={normalItems} loading={!normalItemLoaded}>
          <Product.GridLoading>ローディング中</Product.GridLoading>
          <Product.GridEmpty>
            該当市販品おすすめ商品がないです。
          </Product.GridEmpty>
          <Product.GridItems>
            {normalItems.map((item) => (
              <Product.GridItem key={item.id}>{item}</Product.GridItem>
            ))}
          </Product.GridItems>
        </Product.Grid>
      )}
      <BackBtn url={Path.home}/>
    </DefaultLayout>
  );
}

export default RecommendedProductPage;
