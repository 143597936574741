import React from "react";
import Label from "app/components/common/Label";
import Layout from "app/components/common/Layout";
import Image from "app/components/Image";

function SalonMenuGridItem(props) {
  const { children, onClicked, selected } = props;
  const menuItem = children;

  const onMenuClick = () => {
    if (onClicked) {
      onClicked(menuItem);
    }
  };

  return (
    <Layout.Container
      className={`bg-white px-2 py-6 flex flex-row rounded-lg ${
        selected
          ? "!border-main-default !border-2 !border-solid"
          : "!border-gridItem-border !border-2 !border-solid"
      }`}
      onClick={onMenuClick}
    >
      {menuItem ? (
        <Layout.Row>
          <Layout.Col>
            <Image
              src={menuItem.imageUrl}
              alt={menuItem.name}
            />
          </Layout.Col>
          <Layout.Col grow={true}>
            <p>ID: {menuItem.id}</p>
            <p className="font-bold">Name: {menuItem.name}</p>
            <p>Description: {menuItem.description}</p>
            <p>Duration: {menuItem.duration}</p>
            <Label.Price>Price: {menuItem.price}</Label.Price>
          </Layout.Col>
        </Layout.Row>
      ) : (
        <Layout.Row>メニュー選択しない</Layout.Row>
      )}
    </Layout.Container>
  );
}

export default SalonMenuGridItem;
