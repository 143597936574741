import React from "react";
import Layout from "app/components/common/Layout";
import Image from "app/components/Image";

function SalonArtistGridItem(props) {
  const { children, onClicked, selected } = props;
  const artistItem = children;

  const onArtistClick = () => {
    if (onClicked) {
      onClicked(artistItem);
    }
  };

  return (
    <Layout.Container className={`bg-white px-2 py-6 flex flex-row rounded-lg ${selected ? "!border-main-default !border-2 !border-solid" : "!border-gridItem-border !border-2 !border-solid"}`} onClick={onArtistClick}>
      {artistItem ? (
        <Layout.Row>
          <Layout.Col>
            <Image src={artistItem.imageUrl} alt={artistItem.name} />
          </Layout.Col>
          <Layout.Col grow={true}>
            <p>ID: {artistItem.id}</p>
            <p className="font-bold">Name: {artistItem.name}</p>
            {/* <p>Description: {artistItem.description}</p> */}
            {/* <p>Duration: {artistItem.duration}</p> */}
            {/* <Label.Price>Price: {artistItem.price}</Label.Price> */}
          </Layout.Col>
        </Layout.Row>
      ) : (
        <Layout.Row>美容師指定しない</Layout.Row>
      )}
    </Layout.Container>
  );
}

export default SalonArtistGridItem;
