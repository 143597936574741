import React from "react";
import Layout from "app/components/common/Layout";
import Image from "app/components/Image";

function ProductDetail(props) {
  const { children } = props;
  const productItem = children;

  return (
    <Layout.Container>
      <Layout.Row>
        <Layout.Col>
          <Image
            src={productItem.imageUrl}
            alt={productItem.name}
          />
        </Layout.Col>
        <Layout.Col grow={true}>
          <p className="font-bold text-xl">{productItem.name}</p>
          <p className="font-bold text-gray-400">{productItem.category}</p>
          <p className="font-bold">価格　{productItem.price || 0}円</p>
          <p>{productItem.reason}</p>
        </Layout.Col>
      </Layout.Row>
    </Layout.Container>
  );
}

export default ProductDetail;
