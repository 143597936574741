import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import Label from "app/components/common/Label";
import Button from "app/components/common/Button";
import Modal from "app/components/common/Modal";
import Layout from "app/components/common/Layout";
import { deleteReservationApi } from "app/services/api";
import Image from "app/components/Image";

function ReservationDetail(props) {
  const { children } = props;

  const reservationItem = children;
  // id: 6528cb5418ec003130fb6ece

  const navigate = useNavigate();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showDeleteReconfirmModal, setShowDeleteReconfirmModal] =
    useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  const showDeleteConfirmationModalClicked = (evt) => {
    setShowDeleteConfirmationModal(true);
  };

  const confirmDeleteClicked = (evt) => {
    setShowDeleteConfirmationModal(false);
    setShowDeleteReconfirmModal(true);
  };

  const reconfirmDeleteClicked = async () => {
    await deleteReservationApi(reservationItem.id).then(() => {
      setShowDeleteReconfirmModal(false);
      setShowCompleteModal(true);
    });
  };

  const closeCompleteModalClicked = (evt) => {
    setShowCompleteModal(false);
    navigate(Path.reservation("future"));
  };

  return (
    <>
      <Modal
        show={showDeleteConfirmationModal}
        setShow={setShowDeleteConfirmationModal}
        staticBackdrop={true}
        title="予約をキャンセルしますか？"
        button1={
          <Button.Danger onClick={(evt) => confirmDeleteClicked(evt)}>
            キャンセルを確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteConfirmationModal(false)}>
            閉じる
          </Button>
        }
      >
        Body test
      </Modal>

      <Modal
        show={showDeleteReconfirmModal}
        setShow={setShowDeleteReconfirmModal}
        staticBackdrop={true}
        title="（最終確認）予約をキャンセルしますか？"
        button1={
          <Button.Danger onClick={(evt) => reconfirmDeleteClicked(evt)}>
            キャンセルを確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteReconfirmModal(false)}>
            閉じる
          </Button>
        }
      >
        Body test
      </Modal>

      <Modal
        show={showCompleteModal}
        setShow={setShowCompleteModal}
        staticBackdrop={true}
        title="予約をキャンセルしました。"
        button1={
          <Button.Secondary onClick={(evt) => closeCompleteModalClicked(evt)}>
            終了
          </Button.Secondary>
        }
      >
        Body test
      </Modal>
      <Layout.Container>
        <Layout.Row>
          <Layout.Col>
            <Image
              src={reservationItem.salon.imageUrl}
              alt={reservationItem.salon.name}
            />
          </Layout.Col>
          <Layout.Col grow={true}>
            <p className="font-bold text-xl">{reservationItem.salon.name}</p>
            <Label.DateTime dateFormat="YYYY-MM-DD">
              {reservationItem.visitDate}
            </Label.DateTime>
            <Label.DateTime dateFormat="HH:mm">
              {reservationItem.visitStartTime}
            </Label.DateTime>
            {reservationItem.visitEndTime && (
              <Label.DateTime dateFormat="HH:mm">
                {reservationItem.visitEndTime}
              </Label.DateTime>
            )}
          </Layout.Col>
        </Layout.Row>
        <Layout.Row single={true}>
          <Layout.Col>
            <Button.Danger onClick={showDeleteConfirmationModalClicked}>
              予約をキャンセルする
            </Button.Danger>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </>
  );
}

export default ReservationDetail;
