import React, { useMemo, useRef, useState } from "react";
import Label from "app/components/common/Label";
import Layout from "app/components/common/Layout";
import Image from "app/components/Image";
import SalonArtist from "app/components/SalonArtist";
import SalonMenu from "app/components/SalonMenu";
import SegmentedControl from "app/components/common/SegmentedControl";

function VisitDetail(props) {
  const { children } = props;

  const visitItem = useMemo(() => {
    // format menu
    const menus = children?.visitmenus?.map((item) => {
      const obj = {
        ...item,
        id: item?._id,
        duration: item?.durationNumber,
        price: item?.priceTaxInc,
      };
      delete obj?._id;
      return obj;
    });
    // format artist
    const staff = children?.staff;
    const artist = {
      ...staff,
      id: staff?._id,
      name: staff?.firstNameKanji + staff?.lastNameKanji,
    };
    delete artist?._id;
    //
    const obj = { ...children, menus, artists: [artist] };
    return obj;
  }, [children]);

  const segments = [
    {
      label: "���j���[",
      value: "menu",
      ref: useRef(),
    },
    {
      label: "���e�t",
      value: "artist",
      ref: useRef(),
    },
  ];

  const segmentChanged = (value, index) => {
    setSegmentIndex(index);
  };

  const [segmentIndex, setSegmentIndex] = useState(0);

  return (
    <Layout.Container>
      <Layout.Row>
        <Layout.Col>
          <Image src={visitItem?.salon?.imageUrl} alt={visitItem?.salon?.name} />
        </Layout.Col>
        <Layout.Col grow={true}>
          <p className="font-bold text-xl">{visitItem?.salon?.name}</p>
          <Label.DateTime dateFormat="YYYY-MM-DD">{visitItem?.visitDate}</Label.DateTime>
          {visitItem?.startTime && <Label.DateTime dateFormat="HH:mm">{visitItem?.startTime}</Label.DateTime>}
          {visitItem?.endTime && <Label.DateTime dateFormat="HH:mm">{visitItem?.endTime}</Label.DateTime>}
        </Layout.Col>
      </Layout.Row>
      <Layout.Row single={true}>
        <Layout.Col>
          <SegmentedControl name="group-1" callback={(val, idx) => segmentChanged(val, idx)} controlRef={useRef()} segments={segments} defaultIndex={segmentIndex} />
          {segmentIndex === 0 ? (
            <SalonMenu.Grid>
              {visitItem?.menus?.map((menuItem) => (
                <SalonMenu.GridItem key={menuItem.id}>{menuItem}</SalonMenu.GridItem>
              ))}
            </SalonMenu.Grid>
          ) : (
            <SalonArtist.Grid>
              {visitItem?.artists?.map((artistItem) => (
                <SalonArtist.GridItem key={artistItem.id}>{artistItem}</SalonArtist.GridItem>
              ))}
            </SalonArtist.Grid>
          )}
        </Layout.Col>
      </Layout.Row>
    </Layout.Container>
  );
}

export default VisitDetail;
