import React from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import Button from "app/components/common/Button";

function ReservationButton(props) {
  const { children, className, ...otherProps } = props;
  const salonItem = children;

  const navigate = useNavigate();

  const onMakeReservationClicked = (evt) => {
    // console.log("onMakeReservationClicked");
    // console.log(salonItem);
    navigate(Path.reservationCreate(0), {
      state: {
        salonItem,
      },
    });
  };

  return (
    <Button.Primary
      className={`${className ?? ""}`}
      onClick={onMakeReservationClicked}
      {...otherProps}
    >
      この美容室を予約する
    </Button.Primary>
  );
}

export default ReservationButton;
