import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

function StepsBar(props) {
  const { steps, currentStepIndex } = props;
  // console.log(steps);

  return (
    <Stepper activeStep={currentStepIndex} alternativeLabel>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel className="whitespace-pre-wrap">{step.name}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default StepsBar;
