import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

function DebugJSON(props) {
  const { children } = props;
  const jsonObj = children;

  return (
    <Card className="mx-2">
      <CardContent className="whitespace-pre-wrap">
        {JSON.stringify(jsonObj, null, 2)}
      </CardContent>
    </Card>
  );
}

export default DebugJSON;
