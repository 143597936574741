import React from "react";

function SalonArtistGrid(props) {
  const { children } = props;
  return (
    <div className="w-full h-full overflow-auto bg-list-bg">
      <div className="w-full grid grid-cols-1 gap-4 pt-2 px-2">{children}</div>
    </div>
  );
}

export default SalonArtistGrid;
