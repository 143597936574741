import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import ProductDetail from "app/components/Product/ProductDetail";
import Api from "app/services/api";
import BackBtn from "app/components/common/BackButton";

function RecommendedProductDetailPage(props) {
  const { productId } = useParams();
  const [productItem, setProductItem] = useState();

  const getProductById = async (productId) => {
    const response = await Api.recommendedProducts.find(productId);
    setProductItem(response.data);
  };

  useEffect(() => {
    getProductById(productId);
  }, [productId]);

  return (
    productItem && <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: productItem.name,
      }}
    >
      <ProductDetail>{productItem}</ProductDetail>
      <BackBtn/>
    </DefaultLayout>
  );
}

export default RecommendedProductDetailPage;
