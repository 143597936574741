import ProductGrid from "./ProductGrid";
import ProductGridLoading from "./ProductGridLoading";
import ProductGridEmpty from "./ProductGridEmpty";
import ProductGridItems from "./ProductGridItems";
import ProductGridItem from "./ProductGridItem";
import ProductDetail from "./ProductDetail";

const exportObjs = {
  Grid: ProductGrid,
  GridLoading: ProductGridLoading,
  GridEmpty: ProductGridEmpty,
  GridItems: ProductGridItems,
  GridItem: ProductGridItem,
  Detail: ProductDetail,
};

export default exportObjs;
