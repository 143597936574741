import { getNameFromFirstLast } from "app/utils";
import client from "./client";

import {
  checkScalpLabMember,
  getCustomerInfo,
  updateCustomerInfo,
  deleteCustomer,
  getCustomerBasicInfo,
  updateCustomerBasicInfo,
  searchAllCustomer,
  getReservationInfo,
  getAllCategory,
  getListServiceCategory,
  getAllCounselingSheet,
  addCounselingSheet,
  editCounselingSheet,
  addNewCustomer,
  addNewReservation,
  getAllCustomerVisitHistory,
  getDetailCustomerVisitHistory,
} from "./fake_services";

export const login = (loginName, password) => {
  console.log("Login");
  return client.post("/customer/login", { loginName, password });
};

export const getCurrentUserScalpData = (customerNo) => {
  return client.get("/scalp-data/get-current-user-scalp-data").then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: {} };
  });
};

export const visitHistories = {
  all: () => {
    return client
      .get("/salon/visit/find-customer-visit-history")
      .then((res) => {
        return {
          data: res?.data?.result?.map((item) => {
            return {
              ...item,
              id: item?._id,
              visitStartTime: item?.startTime,
              visitEndTime: item?.finishTime,
            };
          }),
        };
      });
  },
  get: (visitId) => {
    return client
      .get(`/salon/visit/find-customer-visit-history-by-id?id=${visitId}`)
      .then((res) => {
        return { data: res?.data?.result };
      });
  },
};

export const salons = {
  // all: () => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       resolve({
  //         data: fake_salons,
  //       });
  //     }, 1000);
  //   });
  // },
  get: (salonId) => {
    return client.get(`/salon/get?id=${salonId}`).then((res) => {
      console.log("res detail salon", res);
      return { data: res?.data?.result };
    });
  },
  // search: (salonName) => {
  //   const targetSalons = fake_salons.filter((salon) =>
  //     salon.name.includes(salonName)
  //   );
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       resolve({
  //         data: targetSalons,
  //       });
  //     }, 1000);
  //   });
  // },
  // search: (searchText) => {
  //   console.log("search api");
  //   return client
  //     .get("/salon/search", { params: { searchText } })
  //     .then((res) => {
  //       const result = res?.data?.result.map((item) => ({
  //         ...item,
  //         id: item._id,
  //         menus: item.salonMenus.map((i) => ({
  //           ...i,
  //           duration: i.durationString,
  //           id: i._id,
  //           price: i.priceTaxInc,
  //           description: i.description || "",
  //         })),
  //         artists: item.salonStaffs.map((i) => ({
  //           ...i,
  //           name: getNameFromFirstLast(i),
  //           id: i._id,
  //         })),
  //       }));

  //       return { data: result };
  //     })
  // },
  getSalonApi: (searchText) => {
    let promise = client.get("salon/all");

    //if (searchText)
    promise = client.get(`salon/search?searchText=${searchText}`);

    console.log("promise: ", promise);
    return promise.then((res) => {
      const result = res?.data?.result.map((item) => ({
        ...item,
        id: item?._id,
        menus: item?.salonMenus?.map((i) => ({
          ...i,
          duration: i.durationString,
          id: i._id,
          price: i.priceTaxInc,
          description: i.description || "",
        })),
        artists: item?.salonStaffs?.map((i) => ({
          ...i,
          name: getNameFromFirstLast(i),
          id: i._id,
        })),
      }));
      return { data: result };
    });
  },
};

export const deleteReservationApi = (reservationId) =>
  client.delete(`/salon/reservation/delete?id=${reservationId}`);

const reservations = {
  create: ({ payload, callbackSuccess }) => {
    return client
      .post(`/salon/reservation/create-customer-reservation`, payload)
      .then((res) => {
        callbackSuccess && callbackSuccess(res);
        return { res };
      });
  },
  all: () => {
    return client
      .get("/salon/reservation/find-all-current-customer-reservation")
      .then((res) => {
        const result = res?.data?.result.map((item) => ({
          ...item,
          id: item._id,
          reservationDate: item.visitDate,
          reservationStartTime: item.startTime,
          reservationEndTime: item.finishTime,
          visitStartTime: item.startTime,
          visitEndTime: item.finishTime,
        }));

        return { data: result };
      });
  },
};

export const recommendedProducts = {
  exclusive: {
    all: () => {
      return client
        .get("/product/find-all-by-product-scope?productScope=monopoly")
        .then((res) => {
          return {
            data: res?.data?.result?.map((item) => {
              return {
                ...item,
                id: item?._id,
                name: item?.name,
                category: item?.category,
                price: item?.price,
                imageUrl: item?.imageUrl,
                reason: item?.reason,
              };
            }),
          };
        });
    },
  },
  normal: {
    all: () => {
      return client
        .get("/product/find-all-by-product-scope?productScope=general")
        .then((res) => {
          return {
            data: res?.data?.result?.map((item) => {
              return {
                ...item,
                id: item?._id,
                name: item?.name,
                category: item?.category,
                price: item?.price,
                imageUrl: item?.imageUrl,
                reason: item?.reason,
              };
            }),
          };
        });
    },
  },
  find: (productId) => {
    return client
      .get(`product/find-product-detail?id=${productId}`)
      .then((res) => {
        return {
          data: {
            ...res.data.result,
            id: res.data.result?._id,
            name: res.data.result?.name,
            category: res.data.result?.category,
            price: res.data.result?.price,
            imageUrl: res.data.result?.imageUrl,
            reason: res.data.result?.reason,
          },
        };
      });
  },
};

export {
  checkScalpLabMember,
  getCustomerInfo,
  updateCustomerInfo,
  deleteCustomer,
  getCustomerBasicInfo,
  updateCustomerBasicInfo,
  searchAllCustomer,
  getReservationInfo,
  getAllCategory,
  getListServiceCategory,
  getAllCounselingSheet,
  addCounselingSheet,
  editCounselingSheet,
  addNewCustomer,
  addNewReservation,
  getAllCustomerVisitHistory,
  getDetailCustomerVisitHistory,
};

const exportObjs = {
  recommendedProducts,
  visitHistories,
  reservations,
  salons,
};

export default exportObjs;
