import React from "react";

function LayoutCol(props) {
  const { children, className, grow, ...otherProps } = props;

  return (
    <div
      className={`space-y-2 ${grow ? "sm:col-span-2 md:col-span-3" : ""} ${
        className ?? ""
      }`}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export default LayoutCol;
