import React from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import Image from "app/components/Image";

function ProductGridItem(props) {
  const navigate = useNavigate();

  const { children } = props;
  const productItem = children;

  const onProductClicked = () => {
    navigate(Path.recommendedProductDetail(productItem.id), {
      state: { productItem: productItem },
    });
  };

  return (
    <div className="bg-white px-2 py-6" onClick={onProductClicked}>
      <Image
        src={productItem.imageUrl}
        alt={productItem.name}
      />
      <p className="font-bold text-xl pt-6">{productItem.name}</p>
      <p className="font-bold text-gray-400 pt-4">{productItem.category}</p>
      <p className="font-bold pt-4">価格　{productItem.price || 0}円</p>
    </div>
  );
}

export default ProductGridItem;
