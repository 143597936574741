import React from "react";
import Image from "app/components/Image";

function SalonGridItem(props) {
  const { children, onClicked } = props;
  const salonItem = children;

  const onSalonClick = () => {
    if (onClicked) {
      onClicked(salonItem);
    }
  };

  return (
    <div className="bg-white px-2 py-6 flex flex-row" onClick={onSalonClick}>
      <div>
        <Image src={salonItem.imageUrl} alt={salonItem.name} />
      </div>
      <div className="px-2">
        <p className="font-bold">id: {salonItem.id}</p>
        <p className="font-bold">name: {salonItem.name}</p>
      </div>
    </div>
  );
}

export default SalonGridItem;
