import React from "react";
import Button from "app/components/common/Button";

function SecondaryButton(props) {
  const { children, ...otherProps } = props;

  return (
    <Button variant="contained" color="secondary" {...otherProps}>
      {children}
    </Button>
  );
}

export default SecondaryButton;
