import React from "react";
import FormRow from "./FormRow";

function FormSingleRow(props) {
  const { children, className, ...otherProps } = props;

  return (
    <FormRow className={`!flex-col ${className ?? ""}`} {...otherProps}>
      {children}
    </FormRow>
  );
}

export default FormSingleRow;
