import React from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
import Path from "./Path";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import NotFound from "../pages/NotFound";
import MyPage from "../pages/MyPage";
import ScalpDataPage from "../pages/ScalpDataPage";
import SalonSearchPage from "../pages/SalonPage/SalonSearchPage";
import SalonDetailPage from "../pages/SalonPage/SalonDetailPage";
import VisitPage from "../pages/VisitPage";
import VisitHistoryDetailPage from "../pages/VisitPage/VisitHistoryDetailPage";
import ReservationPage from "../pages/ReservationPage";
import ReservationDetailPage from "../pages/ReservationPage/ReservationDetailPage";
import ReservationCreatePage from "../pages/ReservationPage/ReservationCreatePage";
import RecommendedProductPage from "app/pages/RecommendedProductPage";
import RecommendedProductDetailPage from "../pages/RecommendedProductPage/RecommendedProductDetailPage";
// import ListCustomers from "../pages/13/1301";
// import Payment from "../pages/13/1303";
// // import ReservationInfoPage from "../pages/11/ReservationInfoPage";
// import PaymentCash from "../pages/13/1306";

// import FirstTimeCustomerPage from "../pages/11/FirstTimeCustomerPage";
// import ConfirmCustomerInfoPage from "../pages/11/ConfirmCustomerInfoPage";
// import CustomterCounselingSheetPage from "../pages/11/CustomterCounselingSheetPage";
// import CreateNewReservationPage from "../pages/11/CreateNewReservationPage";
// import ConfirmReservationPage from "../pages/11/ConfirmReservationPage";
// // During Service
// import DuringServicePage from "../pages/12/DuringServicePage";

// // Customer Management
// import CustomerManagementTopPage from "../pages/14/CustomerManagementTopPage";
// import DetailCustomerPage from "../pages/14/DetailCustomerPage";
// import Customer1StImpressionAndFactCheckPage from "../pages/14/Customer1StImpressionAndFactCheckPage";
// import CustomerBasicInfoPage from "../pages/14/CustomerBasicInfoPage";
// import CustomerCouselingSheetPage from "../pages/14/CustomerCouselingSheetPage";
// import CustomerVisitHistoryPage from "../pages/14/CustomerVisitHistoryPage";
// import RegisterNewCustomerPage from "../pages/14/RegisterNewCustomerPage";
// import Invoice from "../pages/13/components/Invoice";

function Router() {
  // const token = useSelector((state) => state.auth.token?.accessToken);

  const element = useRoutes([
    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        { path: Path.home, element: <HomePage /> },

        { path: Path.myPage, element: <MyPage /> },

        { path: Path.scalpData, element: <ScalpDataPage /> },

        { path: Path.salonSearch, element: <SalonSearchPage /> },

        { path: Path.salonDetail(), element: <SalonDetailPage /> },

        { path: Path.visit, element: <VisitPage /> },

        {
          path: Path.visitHistoryDetail(),
          element: <VisitHistoryDetailPage />,
        },

        { path: Path.reservation(), element: <ReservationPage /> },

        { path: Path.reservationDetail(), element: <ReservationDetailPage /> },

        { path: Path.reservationCreate(), element: <ReservationCreatePage /> },

        { path: Path.recommended(), element: <RecommendedProductPage /> },

        {
          path: Path.recommendedProductDetail(),
          element: <RecommendedProductDetailPage />,
        },

        // { path: Path.firstTimeCustomer, element: <FirstTimeCustomerPage /> },
        // {
        //   path: Path.confirmCustomerInfo(),
        //   element: <ConfirmCustomerInfoPage />,
        // },
        // {
        //   path: Path.customterCounselingSheet(),
        //   element: <CustomterCounselingSheetPage />,
        // },
        // {
        //   path: Path.createNewReservation(),
        //   element: <CreateNewReservationPage />,
        // },
        // {
        //   path: Path.confirmReservationInfo(),
        //   element: <ConfirmReservationPage />,
        // },

        // { path: Path.payment, element: <ListCustomers /> },
        // { path: Path.paymentCustomer, element: <Payment /> },
        // { path: Path.paymentCash, element: <PaymentCash /> },
        // { path: Path.invoice, element: <Invoice /> },

        // // add other

        // // During Service
        // { path: Path.duringService(), element: <DuringServicePage /> },

        // // Customer Management
        // { path: Path.customerManagement, element: <CustomerManagementTopPage /> },
        // { path: Path.customerManagementRegisterNew, element: <RegisterNewCustomerPage /> },
        // { path: Path.customerManagementBasicInfo(), element: <CustomerBasicInfoPage /> },
        // { path: Path.customerManagementDetail(), element: <DetailCustomerPage /> },
        // { path: Path.customerManagementFactCheck(), element: <Customer1StImpressionAndFactCheckPage /> },
        // { path: Path.customerManagementCounselingSheet(), element: <CustomerCouselingSheetPage /> },
        // { path: Path.customerManagementVisitHistory(), element: <CustomerVisitHistoryPage /> },
      ],
    },

    {
      path: "/",
      element: <AuthRoute />,
      children: [
        { path: Path.login, element: <LoginPage /> },
        // add register, reset_password
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return element;
}

export default function AppRoute() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}
