import React from "react";

function DebugBreakPoint(props) {
  return (
    <div
      className="absolute border-2 border-solid border-red-500"
      style={{ zIndex: 1000 }}
    >
      <div className="hidden xxs:block xs:hidden">xxs(toosmall)</div>
      <div className="hidden xs:block sm:hidden">xs</div>
      <div className="hidden sm:block md:hidden">sm</div>
      <div className="hidden md:block lg:hidden">md</div>
      <div className="hidden lg:block xl:hidden">lg</div>
      <div className="hidden xl:block 2xl:hidden">xl</div>
      <div className="hidden 2xl:block">2xl</div>
    </div>
  );
}

export default DebugBreakPoint;
