import React from "react";

function FormLabel(props) {
  const { children, className, ...otherProps } = props;

  return (
    <label
      className={`text-sm font-medium text-gray-900 dark:text-white col-span-4 ${
        className ?? ""
      }`}
      {...otherProps}
    >
      {children}
    </label>
  );
}

export default FormLabel;
