import React from "react";
import { useLocation } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Salon from "app/components/Salon";

function SalonDetailPage(props) {
  const { state } = useLocation();
  const { salonItem } = state;

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: salonItem.name,
      }}
    >
      <Salon.Detail>{salonItem}</Salon.Detail>
    </DefaultLayout>
  );
}

export default SalonDetailPage;
