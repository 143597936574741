import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Path from "./Path";

export default function AuthRoute() {
  const token = useSelector((state) => state.auth.token?.accessToken);
  const location = useLocation()

  if (!!token && location.pathname === '/login') {
    let redirect = new URLSearchParams(location.search || "").get("redirect");
    redirect = redirect && redirect !== "/" ? redirect : "";

    return (
      <Navigate
        to={redirect || Path.home}
        state={{ from: location }}
        replace
      />
    )
  }
  return <Outlet />
}
