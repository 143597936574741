import React from "react";

function JustifyPanel(props) {
  const { children, className, ...otherProps } = props;

  return (
    <div
      className={`grid grid-cols-2 gap-4 place-content-between ${className}`}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export default JustifyPanel;
