import React from "react";
import FormCol from "./FormCol";

function FormColGrow(props) {
  const { children, ...otherProps } = props;

  return (
    <FormCol grow={true} {...otherProps}>
      {children}
    </FormCol>
  );
}

export default FormColGrow;
