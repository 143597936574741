import React from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import Label from "app/components/common/Label";
import Image from "app/components/Image";

function ReservationGridItem(props) {
  const navigate = useNavigate();

  const { children } = props;
  const reservationItem = children;

  const onReservationClicked = () => {
    navigate(Path.reservationDetail(reservationItem.id), {
      state: { reservationItem: reservationItem },
    });
  };

  return (
    <div
      className="bg-white px-2 py-6 flex flex-row"
      onClick={onReservationClicked}
    >
      <div>
        <Image
          src={reservationItem.salon.imageUrl}
          alt={reservationItem.salon.name}
        />
      </div>
      <div className="px-2">
        <p className="font-bold">id: {reservationItem.id}</p>
        <p className="font-bold">name: {reservationItem.salon.name}</p>
        <Label.DateTime dateFormat="YYYY-MM-DD">
          {reservationItem.reservationDate}
        </Label.DateTime>
        <Label.DateTime dateFormat="HH:mm">
          {reservationItem.reservationStartTime}
        </Label.DateTime>
        {reservationItem.reservationEndTime && (
          <Label.DateTime dateFormat="HH:mm">
            {reservationItem.reservationEndTime}
          </Label.DateTime>
        )}
      </div>
    </div>
  );
}

export default ReservationGridItem;
