import React from "react";

function LayoutContainer(props) {
  const { children, className, ...otherProps } = props;
  return (
    <div className={`space-y-2 ${className ?? ""}`} {...otherProps}>
      {children}
    </div>
  );
}

export default LayoutContainer;
