import React from "react";
import Label from "app/components/common/Label";
import Image from "app/components/Image";

function VisitGridItem(props) {
  const { children, onClicked } = props;
  const visitItem = children;

  const onVisitClick = () => {
    if (onClicked) {
      onClicked(visitItem);
    }
  };

  return (
    <div className="bg-white px-2 py-6 flex flex-row" onClick={onVisitClick}>
      <div>
        <Image src={visitItem?.salon?.imageUrl} alt={visitItem.salon.name} />
      </div>
      <div className="px-2">
        <p className="font-bold">id: {visitItem.id}</p>
        <p className="font-bold">name: {visitItem.salon.name}</p>
        <Label.DateTime dateFormat="YYYY-MM-DD">{visitItem.visitDate}</Label.DateTime>
        {visitItem.visitStartTime && <Label.DateTime dateFormat="HH:mm">{visitItem.visitStartTime}</Label.DateTime>}
        {visitItem.visitEndTime && <Label.DateTime dateFormat="HH:mm">{visitItem.visitEndTime}</Label.DateTime>}
      </div>
    </div>
  );
}

export default VisitGridItem;
