import React from "react";
import { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import SegmentedControl from "app/components/common/SegmentedControl";
import Reservation from "app/components/Reservation";
import SearchBox from "app/components/common/SearchBox";
import Visit from "app/components/Visit";
import BackBtn from "app/components/common/BackButton";

function ReservationPage() {
  const { segmentName } = useParams();
  const navigate = useNavigate();

  const segmentedRef = useRef(null);

  // const [searchQuery, setSearchQuery] = useState(queries.get("q") ?? "");
  const [visitHistories, setVisitHistories] = useState([]);
  const [visitHistoriesLoaded, setVisitHistoriesLoaded] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [reservationsLoaded, setReservationsLoaded] = useState(false);
  const [segmentIndex, setSegmentIndex] = useState(
    segmentName === "visit" ? 1 : 0
  );
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    async function getAllVisitHistories() {
      const response = await Api.visitHistories.all();
      const items = response.data;
      setVisitHistories(items);
      setVisitHistoriesLoaded(true);
    }
    async function getAllReservations() {
      const response = await Api.reservations.all();
      const items = response.data;
      setReservations(items);
      setReservationsLoaded(true);
    }

    getAllVisitHistories();
    getAllReservations();
  }, []);

  const segments = [
    {
      label: "予約中",
      value: "future",
      ref: useRef(),
    },
    {
      label: "来店履歴",
      value: "visit",
      ref: useRef(),
    },
  ];

  const findFromSegmentsOrDefault = (segments, value, defaultIndex = 0) => {
    const index = segments.findIndex((segment) => segment.value === value);
    return index === -1 ? defaultIndex : index;
  };
  const defaultIndex = findFromSegmentsOrDefault(segments, segmentName, 0);

  useEffect(() => {
    // Reload the form page
    setSegmentIndex(defaultIndex);
  }, [segmentIndex, segmentName, defaultIndex]);

  const segmentChanged = (value, index) => {
    setSegmentIndex(index);
    navigate(Path.reservation(value));
  };

  const visitItemClicked = (visitItem) => {
    navigate(Path.visitHistoryDetail(visitItem.id), {
      state: { visitItem: visitItem },
    });
  };

  const searchClicked = async (evt) => {
    navigate(`${Path.salonSearch}?q=${searchText}`);
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "予約",
      }}
    >
      <SearchBox
        placeholder="予約したい美容室を探す"
        value={searchText}
        setValue={setSearchText}
        onSearch={searchClicked}
      />
      <SegmentedControl
        name="group-1"
        callback={(val, idx) => segmentChanged(val, idx)}
        controlRef={segmentedRef}
        segments={segments}
        defaultIndex={segmentIndex}
      />
      {segmentIndex === 0 ? (
        <Reservation.Grid items={reservations} loading={!reservationsLoaded}>
          <Reservation.GridLoading>ローディング中</Reservation.GridLoading>
          <Reservation.GridEmpty>来店履歴がないです。</Reservation.GridEmpty>
          <Reservation.GridItems>
            {reservations.map((item) => (
              <Reservation.GridItem key={item.id}>{item}</Reservation.GridItem>
            ))}
          </Reservation.GridItems>
        </Reservation.Grid>
      ) : (
        <Visit.Grid items={visitHistories} loading={!visitHistoriesLoaded}>
          <Visit.GridLoading>ローディング中</Visit.GridLoading>
          <Visit.GridEmpty>来店履歴がないです。</Visit.GridEmpty>
          <Visit.GridItems>
            {visitHistories?.map((item) => (
              <Visit.GridItem key={item.id} onClicked={visitItemClicked}>
                {item}
              </Visit.GridItem>
            ))}
          </Visit.GridItems>
        </Visit.Grid>
      )}
      <BackBtn url={Path.home} />
    </DefaultLayout>
  );
}

export default ReservationPage;
