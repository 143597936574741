import DatePickerInput from "./DatePickerInput";
import TimePickerInput from "./TimePickerInput";
import FieldInput from "./FieldInput";

const exportObjs = {
  Field: FieldInput,
  DatePicker: DatePickerInput,
  TimePicker: TimePickerInput,
};

export default exportObjs;
