import React from "react";

function PriceLabel(props) {
  const { className, children } = props;

  const price = children;

  return <p className={className}>{price}円</p>;
}

export default PriceLabel;
