import React from "react";
import { useNavigate } from "react-router-dom";
import MainMenuItem from "app/components/MainMenuItem";
import Path from "app/route/Path";

function MyPageMenuItem(props) {
  const navigate = useNavigate();

  const onMenuItemClicked = () => {
    navigate(Path.myPage)
  }

  return (
    <MainMenuItem
      itemTitleString="マイページ"
      onClick={onMenuItemClicked}
      size="100%"
    />
  );
}

export default MyPageMenuItem;
