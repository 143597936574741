import React from "react";

function Form(props) {
  const { children, className, ...otherProps } = props;

  return (
    <form
      className={`space-y-4 md:space-y-6 ${className ?? ""}`}
      {...otherProps}
    >
      {children}
    </form>
  );
}

export default Form;
