import React, { useEffect, useState } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import ScoreGraph from "app/components/ScalpData/ScoreGraph";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getCurrentUserScalpData } from "app/services/api";
import BackBtn from "app/components/common/BackButton"
import Path from "app/route/Path";

function ScalpDataPage(props) {
  const {} = useParams();
  const navigate = useNavigate();
  var isDetail = true;
  const [fetchHistoryOrderDetail, setFetchHistoryOrderDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadHistoryOrderDetail = () => {
    setFetchHistoryOrderDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    getCurrentUserScalpData()
      .then((res) => {
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadHistoryOrderDetail();
  }, []);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "頭皮データ",
      }}
    >
      {fetchHistoryOrderDetail.data?.map((item, index) => (
        <ScoreGraph key={index} data={item} />
      ))}
      <BackBtn url={Path.myPage}/>
      {/* <ScoreGraph data={fetchHistoryOrderDetail.data} /> */}
    </DefaultLayout>
  );
}

export default ScalpDataPage;
