import React from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainMenuGrid from "app/components/MainMenuGrid";
import MainMenuItem from "app/components/MainMenuItem";
import BackBtn from "app/components/common/BackButton";
import Path from "app/route/Path";

function MyPage() {
  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "マイページ",
      }}>
      <MainMenuGrid>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.ScalpData />
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.VisitHistory />
        </div>
      </MainMenuGrid>
      <BackBtn url={Path.home} />
    </DefaultLayout>
  );
}

export default MyPage;
