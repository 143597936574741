import React from "react";

function ScoreGraph(props) {
  const { data, children } = props;

  return (
    <div>
      <p className="whitespace-nowrap px-6 py-4">
        キットID　：　
        {data?.scalpData.inspectionKitId}
      </p>
      <p className="whitespace-nowrap px-6 py-4">
        クラスター　：　
        {data?.scalpData.factorCluster?.toFixed(1) ?? "分析中"}
      </p>
      <p className="whitespace-nowrap px-6 py-4">
        細胞面積　：　
        {data?.scalpData.factorCellArea?.toFixed(1) ?? "分析中"}
      </p>
      <p className="whitespace-nowrap px-6 py-4">
        多重剥離　：　
        {data?.scalpData.factorPeeling?.toFixed(1) ?? "分析中"}
      </p>
      <p className="whitespace-nowrap px-6 py-4">
        CP　：　
        {data?.scalpData.factorCP?.toFixed(1) ?? "分析中"}
      </p>
      <p className="whitespace-nowrap px-6 py-4">
        総合　：　
        {data?.scalpData.factorScore?.toFixed(1) ?? "分析中"}
      </p>
      <p className="whitespace-nowrap px-6 py-4">
        ------------------------------------------
      </p>
    </div>
  );
}

export default ScoreGraph;
