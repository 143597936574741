import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Visit from "app/components/Visit";
import Reservation from "app/components/Reservation";
import Layout from "app/components/common/Layout";
import { getNameFromFirstLast } from "app/utils/index";
import BackBtn from "../../../components/common/BackButton";
import Path from "app/route/Path";

function VisitHistoryDetailPage() {
  const { pathname } = useLocation();
  const visitId = pathname?.split("/")[3];

  const [salonItem, setSalonItem] = useState({});
  const [salonItemLoaded, setSalonItemLoaded] = useState(false);
  const [visitData, setVisitData] = useState({
    salon: { imageUrl: "", name: "" },
    visitDate: null,
    startTime: null,
    endTime: null,
  });

  const getVisitData = async (visitId) => {
    const response = await Api.visitHistories.get(visitId);
    setVisitData({
      ...response?.data,
      salon: {
        ...response?.data?.salonCustomer?.salon,
      },
    });
  };

  const getDetailSalonData = async (salonId) => {
    const response = await Api.salons.get(salonId);
    setSalonItem(response?.data);
    setSalonItemLoaded(true);
  };

  useEffect(() => {
    getVisitData(visitId);
  }, [visitId]);

  useEffect(() => {
    getDetailSalonData(visitData?.salon?._id);
  }, [visitData]);

  const formatedSalonItem = useMemo(() => {
    const { _id: id, salonMenus, salonStaffs } = salonItem || {};
    const menus = salonMenus?.map((menu) => {
      return {
        imageUrl: menu?.imageUrl,
        name: menu?.name,
        id: menu?._id,
        description: menu?.description,
        duration: menu?.durationNumber,
        price: menu?.priceTaxExc,
      };
    });

    const artists = salonStaffs?.map((artist) => {
      return {
        imageUrl: artist?.imageUrl,
        name: getNameFromFirstLast(artist),
        id: artist?._id,
        // description: artist?.description,
        // duration: artist?.durationNumber,
        // price: artist?.priceTaxExc,
      };
    });
    return {
      ...salonItem,
      id,
      menus,
      artists,
    };
  }, [salonItem]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: visitData?.name,
      }}>
      <div className="space-y-4">
        <Visit.Detail>{visitData}</Visit.Detail>
        {salonItemLoaded === true && (
          <Layout.Row single={true} className="!sticky !bottom-2">
            <Layout.Col>
              <Reservation.Button>{formatedSalonItem}</Reservation.Button>
            </Layout.Col>
          </Layout.Row>
        )}
      </div>
      <BackBtn />
    </DefaultLayout>
  );
}

export default VisitHistoryDetailPage;
