import React from "react";
import MyPageMenuItem from "./MyPageMenuItem";
import RecommendedMenuItem from "./RecommendedMenuItem";
import ReservationMenuItem from "./ReservationMenuItem";
import ScalpDataMenuItem from "./ScalpDataMenuItem";
import VisitHistoryMenuItem from "./VisitHistoryMenuItem";

function MainMenuItem(props) {
  const {
    size,
    borderSize,
    itemTitle,
    itemTitleString,
    onClick,
    isActive,
    disabled,
  } = props;

  const itemTitleStringToUi = (titleString) => {
    const splittedTitle = titleString.split("\\n");

    return (
      <>
        {splittedTitle.map((title, idx) => (
          <p key={idx}>{title}</p>
        ))}
      </>
    );
  };

  return (
    <button
      type="button"
      className={`rounded-full overflow-hidden group relative ${
        disabled
          ? isActive
            ? "!border-main-hover"
            : "!border-main-default"
          : isActive
          ? "!border-main-hover"
          : "border-main-default"
      } hover:border-main-hover`}
      style={{
        width: typeof size === "number" ? `${size}px` : size,
        height: typeof size === "number" ? `${size}px` : size,
      }}
      onClick={() => {
        onClick && !disabled && onClick();
      }}
    >
      <div
        className={`w-full h-full rounded-full
        ${
          disabled
            ? isActive
              ? "!border-main-hover cursor-default"
              : "!border-main-default cursor-default"
            : isActive
            ? "!border-main-hover cursor-pointer"
            : "border-main-default cursor-pointer"
        } group-hover:border-main-hover`}
        style={{
          borderWidth:
            typeof borderSize === "number"
              ? `${borderSize}px`
              : typeof borderSize === "string"
              ? borderSize
              : "5px",
        }}
      ></div>
      <div className="absolute inset-0 rounded-full overflow-hidden text-white  ">
        <div className="w-full h-[36%] bg-transparent"></div>
        <div
          className={`w-full h-[64%] bg-main-default group-hover:bg-main-hover pb-[10%] flex flex-col items-center justify-center
        ${
          disabled
            ? isActive
              ? "!bg-main-hover"
              : "!bg-main-default"
            : isActive
            ? "!bg-main-hover"
            : "bg-main-default"
        } group-hover:bg-main-hover
        `}
        >
          {itemTitleString ? itemTitleStringToUi(itemTitleString) : itemTitle}
        </div>
      </div>
    </button>
  );
}

MainMenuItem.defaultProps = {
  size: 120,
};

export default MainMenuItem;

MainMenuItem.MyPage = MyPageMenuItem;
MainMenuItem.Recommended = RecommendedMenuItem;
MainMenuItem.Reservation = ReservationMenuItem;
MainMenuItem.ScalpData = ScalpDataMenuItem;
MainMenuItem.VisitHistory = VisitHistoryMenuItem;
