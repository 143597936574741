import React from "react";
import ReservationGridLoading from "./ReservationGridLoading";
import ReservationGridEmpty from "./ReservationGridEmpty";
import ReservationGridItems from "./ReservationGridItems";

function ReservationGrid(props) {
  const { children, items, loading } = props;
  let loadingChild = <p>ローディング中</p>;
  let emptyChild = <p>該当アイテムがありません</p>;
  let itemsChild = <div></div>;

  children.forEach((child, idx) => {
    if (child.type === ReservationGridLoading) {
      loadingChild = child;
      return;
    } else if (child.type === ReservationGridEmpty) {
      emptyChild = child;
      return;
    } else if (child.type === ReservationGridItems) {
      itemsChild = child;
      return;
    }
    return;
  });

  return (
    <div className="w-full h-full overflow-auto bg-list-bg">
      <div className="w-full grid grid-cols-1 gap-4 pt-2 px-2">
        {loading ? loadingChild : items?.length === 0 ? emptyChild : itemsChild}
      </div>
    </div>
  );
}

export default ReservationGrid;
