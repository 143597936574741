import React from "react";
import ProductGridLoading from "./ProductGridLoading";
import ProductGridEmpty from "./ProductGridEmpty";
import ProductGridItems from "./ProductGridItems";

function ProductGrid(props) {
  const { children, items, loading } = props;
  let loadingChild = <p>ローディング中</p>;
  let emptyChild = <p>該当アイテムがありません</p>;
  let itemsChild = <div></div>;

  children.forEach((child, idx) => {
    if (child.type === ProductGridLoading) {
      loadingChild = child;
      return;
    } else if (child.type === ProductGridEmpty) {
      emptyChild = child;
      return;
    } else if (child.type === ProductGridItems) {
      itemsChild = child;
      return;
    }
    return;
  });

  return (
    <div className="w-full h-full overflow-auto bg-list-bg">
      <div className="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 pt-2 px-2">
        {loading ? loadingChild : items?.length === 0 ? emptyChild : itemsChild}
      </div>
    </div>
  );
}

export default ProductGrid;
