import LayoutContainer from "./LayoutContainer";
import LayoutRow from "./LayoutRow";
import LayoutCol from "./LayoutCol";

const exportObjs = {
  Container: LayoutContainer,
  Col: LayoutCol,
  Row: LayoutRow,
};

export default exportObjs;
