import ReservationGrid from "./ReservationGrid";
import ReservationGridLoading from "./ReservationGridLoading";
import ReservationGridEmpty from "./ReservationGridEmpty";
import ReservationGridItems from "./ReservationGridItems";
import ReservationGridItem from "./ReservationGridItem";
import ReservationDetail from "./ReservationDetail";
import ReservationForm from "./ReservationForm";
import ReservationButton from "./ReservationButton";

const exportObjs = {
  Grid: ReservationGrid,
  GridLoading: ReservationGridLoading,
  GridEmpty: ReservationGridEmpty,
  GridItems: ReservationGridItems,
  GridItem: ReservationGridItem,
  Detail: ReservationDetail,
  Form: ReservationForm,
  Button: ReservationButton,
};

export default exportObjs;
