import React from "react";
import { useState, useRef } from "react";
import Layout from "app/components/common/Layout";
import Reservation from "app/components/Reservation";
import SalonArtist from "app/components/SalonArtist";
import SalonMenu from "app/components/SalonMenu";
import SegmentedControl from "app/components/common/SegmentedControl";
import Image from "app/components/Image";

function SalonDetail(props) {
  const { children } = props;
  const salonItem = children;

  const segmentChanged = (value, index) => {
    setSegmentIndex(index);
  };

  const [segmentIndex, setSegmentIndex] = useState(0);
  const segments = [
    {
      label: "メニュー",
      value: "menu",
      ref: useRef(),
    },
    {
      label: "美容師",
      value: "artist",
      ref: useRef(),
    },
  ];

  return (
    <Layout.Container>
      <Layout.Row>
        <Layout.Col>
          <Image
            src={salonItem.imageUrl}
            alt={salonItem.name}
          />
        </Layout.Col>
        <Layout.Col grow={true}>
          <p className="font-bold text-xl">{salonItem.name}</p>
        </Layout.Col>
      </Layout.Row>
      <Layout.Row single={true}>
        <Layout.Col>
          <SegmentedControl
            name="group-1"
            callback={(val, idx) => segmentChanged(val, idx)}
            controlRef={useRef()}
            segments={segments}
            defaultIndex={segmentIndex}
          />
          {segmentIndex === 0 ? (
            <SalonMenu.Grid>
              {salonItem?.menus?.map((menuItem) => (
                <SalonMenu.GridItem key={menuItem.id}>
                  {menuItem}
                </SalonMenu.GridItem>
              ))}
            </SalonMenu.Grid>
          ) : (
            <SalonArtist.Grid>
              {salonItem?.artists?.map((artistItem) => (
                <SalonArtist.GridItem key={artistItem.id}>
                  {artistItem}
                </SalonArtist.GridItem>
              ))}
            </SalonArtist.Grid>
          )}
        </Layout.Col>
      </Layout.Row>
      <div className="h-4"></div>
      <Layout.Row single={true} className="!sticky !bottom-2">
        <Layout.Col>
          <Reservation.Button>{salonItem}</Reservation.Button>
        </Layout.Col>
      </Layout.Row>
    </Layout.Container>
  );
}

export default SalonDetail;
