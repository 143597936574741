import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Visit from "app/components/Visit";
import BackBtn from "app/components/common/BackButton";

function VisitPage() {
  const navigate = useNavigate();

  const [visitHistories, setVisitHistories] = useState([]);
  const [visitHistoriesLoaded, setVisitHistoriesLoaded] = useState(false);

  useEffect(() => {
    async function getAllVisitHistories() {
      const response = await Api.visitHistories.all();
      setVisitHistories(response.data);
      setVisitHistoriesLoaded(true);
    }
    getAllVisitHistories();
  }, []);

  const visitItemClicked = (visitItem) => {
    navigate(Path.visitHistoryDetail(visitItem.id), {
      state: { visitItem: visitItem },
    });
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "来店履歴",
      }}
    >
      <Visit.Grid items={visitHistories} loading={!visitHistoriesLoaded}>
        <Visit.GridLoading>ローディング中</Visit.GridLoading>
        <Visit.GridEmpty>来店履歴がないです。</Visit.GridEmpty>
        <Visit.GridItems>
          {Array.isArray(visitHistories)
            ? visitHistories?.map((item) => (
                <Visit.GridItem key={item.id} onClicked={visitItemClicked}>
                  {item}
                </Visit.GridItem>
              ))
            : []}
        </Visit.GridItems>
      </Visit.Grid>
      <BackBtn url={Path.myPage}/>
    </DefaultLayout>
  );
}

export default VisitPage;
