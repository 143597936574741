import React from "react";
import Button from "app/components/common/Button";

function DangerButton(props) {
  const { children, ...otherProps } = props;

  return (
    <Button variant="contained" color="error" {...otherProps}>
      {children}
    </Button>
  );
}

export default DangerButton;
