import SalonGrid from "./SalonGrid";
import SalonGridPending from "./SalonGridPending";
import SalonGridLoading from "./SalonGridLoading";
import SalonGridEmpty from "./SalonGridEmpty";
import SalonGridItems from "./SalonGridItems";
import SalonGridItem from "./SalonGridItem";
import SalonDetail from "./SalonDetail";

const exportObjs = {
  Grid: SalonGrid,
  GridPending: SalonGridPending,
  GridLoading: SalonGridLoading,
  GridEmpty: SalonGridEmpty,
  GridItems: SalonGridItems,
  GridItem: SalonGridItem,
  Detail: SalonDetail,
};

export default exportObjs;
