import React from "react";
import SalonGridPending from "./SalonGridPending";
import SalonGridLoading from "./SalonGridLoading";
import SalonGridEmpty from "./SalonGridEmpty";
import SalonGridItems from "./SalonGridItems";

function SalonGrid(props) {
  const { children, items, loading, pending } = props;
  let pendingChild = <p>ボタンを押してください</p>;
  let loadingChild = <p>ローディング中</p>;
  let emptyChild = <p>該当アイテムがありません</p>;
  let itemsChild = <div></div>;

  children.forEach((child, idx) => {
    if (child.type === SalonGridPending) {
      pendingChild = child;
    } else if (child.type === SalonGridLoading) {
      loadingChild = child;
      return;
    } else if (child.type === SalonGridEmpty) {
      emptyChild = child;
      return;
    } else if (child.type === SalonGridItems) {
      itemsChild = child;
      return;
    }
    return;
  });

  return (
    <div className="w-full h-full overflow-auto bg-list-bg">
      <div className="w-full grid grid-cols-1 gap-4 pt-2 px-2">
        {pending
          ? pendingChild
          : loading
          ? loadingChild
          : items.length === 0
          ? emptyChild
          : itemsChild}
      </div>
    </div>
  );
}

export default SalonGrid;
