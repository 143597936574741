// const customerManagementPrefix = "/customer-management";

const routePaths = {
  home: "/",
  login: "/login",
  myPage: "/my-page",
  scalpData: "/scalp-data",
  salonSearch: "/salon/search",
  salonDetail: (salonId) => {
    if (salonId === undefined || salonId === null) {
      return "/salon/detail/:salonId";
    }

    return `/salon/detail/${salonId}`;
  },
  visit: "/visit",
  visitHistoryDetail: (historyId) =>
    historyId ? `/visit/history/${historyId}` : `/visit/history/:historyId`,
  reservation: (segmentId) => {
    if (segmentId === undefined || segmentId === null) {
      return `/reservation/:segmentName`;
    }

    if (segmentId === "future" || segmentId === "visit") {
      return `/reservation/${segmentId}`;
    }

    return `/reservation/future`;
  },
  reservationDetail: (reservationId) =>
    reservationId
      ? `/reservation/detail/${reservationId}`
      : `/reservation/detail/:reservationId`,
  reservationCreate: (stepId) => {
    if (stepId === undefined || stepId === null) {
      return `/reservation/create/:stepId`;
    }

    if (stepId >= 0 && stepId <= 3) {
      return `/reservation/create/${stepId}`;
    }

    return `/reservation/create/0`;
  },
  recommended: (segmentId) => {
    if (segmentId === undefined || segmentId === null) {
      return `/recommended/:segmentName`;
    }

    if (segmentId === "normal" || segmentId === "exclusive") {
      return `/recommended/${segmentId}`;
    }

    return `/recommended/exclusive`;
  },
  recommendedProductDetail: (productId) =>
    productId
      ? `/recommended/product/${productId}`
      : `/recommended/product/:productId`,
};

export default routePaths;
