import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Path from "app/route/Path";
import StepsBar from "app/components/common/StepsBar";
import SalonMenu from "app/components/SalonMenu";
import SalonArtist from "app/components/SalonArtist";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import Api from "app/services/api";
import BackBtn from "../common/BackButton";

function ReservationForm(props) {
  const steps = [{ name: "メニュー選択" }, { name: "日時指定\n担当選択" }, { name: "内容確認" }, { name: "予約完了" }];

  const { children } = props;
  const salonItem = children;

  const navigate = useNavigate();

  const { stepId } = useParams();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [hasSelectedMenu, setHasSelectedMenu] = useState(false);
  const [hasSelectedArtist, setHasSelectedArtist] = useState(false);

  const {
    handleSubmit,
    // handleChange,
    // errors,
    values,
    setFieldValue,
    // resetForm,
    // touched,
    // handleBlur,
  } = useFormik({
    initialValues: {
      selectedSalonId: salonItem.id,
      visitDate: dayjs(),
      visitTime: dayjs(),
      selectedMenuItemId: "",
      selectedArtistItemId: "",
      selectedMenuItem: {},
    },
    onSubmit: async (values) => {
      await Api.reservations.create({
        payload: {
          visitDate: values?.visitDate.toISOString(),
          startTime: values?.visitTime.toISOString(),
          // finishTime: null,
          staffId: values?.selectedArtistItemId,
          salonMenuIds: [values?.selectedMenuItemId],
          salonId: values?.selectedSalonId,
        },
        callbackSuccess: gotoNextStep,
      });
    },
  });

  useEffect(() => {
    // Reload the form page
    setCurrentStepIndex(+stepId);
  }, [stepId]);

  const menuItemClicked = (menuItem) => {
    if (!menuItem) {
      // メニュー選択しない
      setFieldValue("selectedMenuItemId", "");
      setFieldValue("selectedMenuItem", {});
      setHasSelectedMenu(true);
    } else {
      setFieldValue("selectedMenuItemId", menuItem.id);
      setFieldValue("selectedMenuItem", menuItem);
      setHasSelectedMenu(true);
    }
  };

  const artistItemClicked = (artistItem) => {
    if (!artistItem) {
      // 美容師指名しない
      setFieldValue("selectedArtistItemId", "");
      setFieldValue("selectedArtistItem", {});
      setHasSelectedArtist(true);
    } else {
      setFieldValue("selectedArtistItemId", artistItem.id);
      setFieldValue("selectedArtistItem", artistItem);
      setHasSelectedArtist(true);
    }
  };

  const gotoPrevStep = () => {
    navigate(Path.reservationCreate(currentStepIndex - 1), {
      state: {
        salonItem,
      },
    });
  };

  const gotoNextStep = () => {
    navigate(Path.reservationCreate(currentStepIndex + 1), {
      state: {
        salonItem,
      },
    });
  };

  const step1IsValid = () => {
    if (!hasSelectedMenu) {
      return false;
    }

    return true;
  };

  const step2IsValid = () => {
    if (!hasSelectedArtist) {
      return false;
    }

    return true;
  };

  const reservationFinished = () => {
    navigate(Path.reservation("future"));
  };

  const renderByStepIndex = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <SalonMenu.Grid>
              <SalonMenu.GridItem onClicked={menuItemClicked} selected={hasSelectedMenu && "" === values.selectedMenuItemId}></SalonMenu.GridItem>
              {salonItem?.menus?.map((menu, index) => (
                <SalonMenu.GridItem key={index} onClicked={menuItemClicked} selected={menu.id === values.selectedMenuItemId}>
                  {menu}
                </SalonMenu.GridItem>
              ))}
            </SalonMenu.Grid>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <BackBtn/>
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid()}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 1:
        return (
          <>
            <Form.Row className="mx-2">
              <Form.Col>
                <Form.Label htmlFor="visitDate">来店日</Form.Label>
              </Form.Col>
              <Form.ColGrow>
                <Input.DatePicker
                  name="visitDate"
                  className="w-full"
                  value={values.visitDate}
                  disablePast
                  setValue={(newValue) => {
                    setFieldValue("visitDate", newValue);
                  }}></Input.DatePicker>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row className="mx-2">
              <Form.Col>
                <Form.Label htmlFor="visitTime">来店時間</Form.Label>
              </Form.Col>
              <Form.ColGrow>
                <Input.TimePicker
                  name="visitTime"
                  className="w-full"
                  value={values.visitTime}
                  setValue={(newValue) => {
                    setFieldValue("visitTime", newValue);
                  }}></Input.TimePicker>
              </Form.ColGrow>
            </Form.Row>

            <SalonArtist.Grid>
              <SalonArtist.GridItem onClicked={artistItemClicked} selected={hasSelectedArtist && "" === values.selectedArtistItemId}></SalonArtist.GridItem>
              {salonItem?.artists?.map((artist, index) => (
                <SalonArtist.GridItem key={index} onClicked={artistItemClicked} selected={artist.id === values.selectedArtistItemId}>
                  {artist}
                </SalonArtist.GridItem>
              ))}
            </SalonArtist.Grid>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>修正（戻る）</Button>
              <Button.Primary onClick={gotoNextStep} disabled={!step2IsValid()}>
                最終確認へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );

      case 2:
        return (
          <div className="mx-2 space-y-4">
            <Form.Row>
              <Form.Col>サロン名</Form.Col>
              <Form.ColGrow>{salonItem?.name}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>来店日時</Form.Col>
              <Form.ColGrow>
                <Label.DateTime dateFormat="YYYY-MM-DD">{values.visitDate}</Label.DateTime>
                <Label.DateTime dateFormat="HH:mm">{values.visitTime}</Label.DateTime>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>メニュー</Form.Col>
              <Form.ColGrow>{values.selectedMenuItemId === "" ? <p>メニュー選択なし</p> : <SalonMenu.GridItem>{values.selectedMenuItem}</SalonMenu.GridItem>}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>担当者</Form.Col>
              <Form.ColGrow>{values.selectedArtistItemId === "" ? <p>美容師指名なし</p> : <SalonArtist.GridItem>{values.selectedArtistItem}</SalonArtist.GridItem>}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>お支払い金額</Form.Col>
              <Form.ColGrow>{values.selectedMenuItemId === "" ? <p>未定</p> : <Label.Price>{values.selectedMenuItem.price}</Label.Price>}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>要望・その他</Form.Col>
              <Form.ColGrow></Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>注意事項</Form.Col>
              <Form.ColGrow></Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary onClick={handleSubmit}>この内容で予約を確認する</Button.Primary>
            </Panel.Justify>
          </div>
        );

      case 3:
        return (
          <div className="text-center space-y-4 mx-2">
            <Form.Row>
              <Form.ColGrow>予約完了</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.ColGrow>
                <Button.Primary onClick={reservationFinished}>完了</Button.Primary>
              </Form.ColGrow>
            </Form.Row>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <StepsBar steps={steps} currentStepIndex={currentStepIndex} />
      <Form.Form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        {renderByStepIndex(currentStepIndex)}
      </Form.Form>
    </>
  );
}

export default ReservationForm;
