import Form from "./Form";
import FormCol from "./FormCol";
import FormColGrow from "./FormColGrow";
import FormLabel from "./FormLabel";
import FormRow from "./FormRow";
import FormSingleRow from "./FormSingleRow";

const exportObjs = {
  Form: Form,
  Row: FormRow,
  SingleRow: FormSingleRow,
  ColGrow: FormColGrow,
  Col: FormCol,
  Label: FormLabel,
};

export default exportObjs;
