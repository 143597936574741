import React, { useState } from "react";
import { Link } from "react-router-dom";
import { setAuthUser, setAuthToken } from "app/redux/authReducer";
import { useDispatch } from "react-redux";
import logo from "app/assets/images/logo.png";
import Form from "app/components/common/Form";
import Button from "app/components/common/Button";
import { login } from "../../services/api";
import jwtDecode from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";

function LoginPage() {
  const dispatch = useDispatch();
  const [loginName, setLoginName] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    login(loginName, password)
      .then((res) => {
        // const tokenData = jwtDecode(res.data.result.accessToken);

        dispatch(
          setAuthUser({
            id: res.data.result.id,
            loginName: res.data.result.loginName,
          })
        );
        dispatch(
          setAuthToken({
            accessToken: res.data.result.accessToken,
          })
        );
      })
      .catch((err) => {
        console.log("login error");
        toast.error("ログイン情報が間違っています");
      });
  };

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <h2 className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
            <img
              className="w-12 h-12 mr-3"
              src={logo}
              alt="beauty-salon-system"
            />
            頭皮ラボ　お客様システム
          </h2>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                ログイン
              </h1>
              <Form.Form action="#">
                <Form.SingleRow>
                  <Form.ColGrow>
                    <Form.Label htmlFor="loginName">メール</Form.Label>
                  </Form.ColGrow>
                  <Form.ColGrow>
                    <input
                      type="email"
                      name="loginName"
                      id="loginName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="メール"
                      required=""
                      value={loginName}
                      onChange={(e) => setLoginName(e.currentTarget.value)}
                    />
                  </Form.ColGrow>
                </Form.SingleRow>
                <Form.SingleRow>
                  <Form.ColGrow>
                    <Form.Label htmlFor="password">パスワード</Form.Label>
                  </Form.ColGrow>
                  <Form.ColGrow>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                      value={password}
                      onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                  </Form.ColGrow>
                </Form.SingleRow>
                <Form.SingleRow>
                  <Form.ColGrow>
                    <Link
                      to="/reset-password"
                      className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                    >
                      パスワード忘れた？
                    </Link>
                  </Form.ColGrow>
                  <Form.ColGrow>
                    <Button.Primary onClick={handleLogin}>
                      ログイン
                    </Button.Primary>
                  </Form.ColGrow>
                </Form.SingleRow>
              </Form.Form>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
    </>
  );
}

export default LoginPage;
